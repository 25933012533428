<template>
  <v-row dense>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-form
        ref="form"
        class="ma-0"
      >
        <default-text-field
          @keypress.enter.prevent="searchVolume(volumeCode)"
          label="Código do Volume"
          v-model="volumeCode"
          autofocus
          :hide-details="false"
          :rules="[rules.required]"
        ></default-text-field>
      </v-form>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      md="3"
      lg="2"
    >
      <default-button @click="searchVolume(volumeCode)"
        >Pesquisar</default-button
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultTextField from "../../../shared/components/vuetify/DefaultTextField.vue";
export default {
  name: "TheHeader",
  components: {
    DefaultButton,
    DefaultTextField,
  },
  data() {
    return {
      volumeCode: "",
    };
  },
  methods: {
    ...mapActions("panelInspection", ["volumeDetails"]),
    searchVolume(volumeCode) {
      if (this.$refs.form.validate()) {
        this.volumeDetails(volumeCode);
      }
    },
  },
  computed: {
    ...mapGetters("panelInspection", ["getIsLoadingVolumeDetails"]),
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
      };
    },
  },
};
</script>

<style scoped></style>
