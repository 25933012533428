<template>
  <div class="text--text">
    <v-form ref="form">
      <v-row
        dense
        v-for="(panel, index) in volumeDetails.tipoPaineil"
        :key="index"
      >
        <v-col
          cols="12"
          sm="8"
          md="5"
          xl="4"
        >
          <default-text-field
            readonly
            :key="index"
            :value="panel.painelDescricao"
            label="Modelo de Painel"
            :clearable="false"
        /></v-col>
        <v-col
          cols="12"
          sm="4"
          md="3"
          xl="2"
        >
          <default-text-field
            :hide-details="false"
            type="number"
            :clearable="false"
            min="1"
            :rules="[rules.required, rules.isValid]"
            label="Quantidade de Painéis"
            @input="updateOrderPanels(index, panel.painelId, $event)"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row dense>
      <v-col
        cols="6"
        md="3"
        ><default-button
          @click="confirmOrder(orderToBeConfirmed)"
          :loading="isLoading"
          color="green white--text"
          >Confirmar Volume</default-button
        ></v-col
      >
      <v-col
        cols="6"
        md="3"
        ><default-button
          @click="openBlockOrderModal"
          color="pink white--text"
          >Bloquear Pedido</default-button
        ></v-col
      >
    </v-row>
    <block-order
      v-model="isBlockOrderModalOpen"
      :key="reRender"
      :panel-models="volumeDetails.tipoPaineil"
    />
    <inspection-completed
      v-model="isInspectionCompletedModalOpen"
      :order-number="volumeDetails.numeroPedido"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import BlockOrder from "./BlockOrder.vue";
import InspectionCompleted from "./InspectionCompleted.vue";

export default {
  name: "PanelConfirmation",
  components: {
    DefaultButton,
    DefaultTextField,
    BlockOrder,
    InspectionCompleted,
  },
  data() {
    return {
      isLoading: false,
      reRender: 0,
      orderPanels: [],
    };
  },
  props: {
    volumeDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("panelInspection", [
      "setIsBlockOrderModalOpen",
      "setIsInspectionCompletedModalOpen",
    ]),
    openBlockOrderModal() {
      this.reRender++;
      this.setIsBlockOrderModalOpen(true);
    },
    async confirmOrder(orderToBeConfirmed) {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        await this.$store.dispatch(
          "panelInspection/confirmOrder",
          orderToBeConfirmed
        );
      }
      this.isLoading = false;
    },
    updateOrderPanels(index, panelId, panelQuantity) {
      this.$set(this.orderToBeConfirmed.paineis, index, {
        painelId: panelId,
        quantidade: Number(panelQuantity),
      });
    },
  },
  computed: {
    ...mapGetters("panelInspection", [
      "getIsBlockOrderModalOpen",
      "getIsInspectionCompletedModalOpen",
    ]),
    isBlockOrderModalOpen: {
      get() {
        return this.getIsBlockOrderModalOpen;
      },
      set(newValue) {
        this.setIsBlockOrderModalOpen(newValue);
      },
    },
    isInspectionCompletedModalOpen: {
      get() {
        return this.getIsInspectionCompletedModalOpen;
      },
      set(newValue) {
        this.setIsInspectionCompletedModalOpen(newValue);
      },
    },
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
        isValid: (input) =>
          /^[1-9]\d*$/.test(input) || "Informe número inteiro a partir de 1",
      };
    },
    orderToBeConfirmed() {
      return {
        numeroPedido: String(this.volumeDetails.numeroPedido),
        volumeId: this.volumeDetails.volumeId,
        codigoVolume: String(this.volumeDetails.volumeCodigo),
        paineis: [],
      };
    },
  },
};
</script>

<style scoped></style>
