<template>
  <div>
    <template v-if="!getIsLoadingVolumeDetails">
      <template v-if="hasVolumeDetails">
        <volume-details
          class="mt-10 mt-sm-2"
          :volume-details="getVolumeDetails"
        />
        <panel-confirmation
          class="mt-5"
          :volume-details="getVolumeDetails"
        />
      </template>
    </template>
    <default-spinner v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import PanelConfirmation from "./PanelConfirmation.vue";
import VolumeDetails from "./VolumeDetails.vue";

export default {
  name: "TheListing",
  components: {
    DefaultSpinner,
    PanelConfirmation,
    VolumeDetails,
  },
  beforeDestroy() {
    this.resetVolumeDetails();
  },

  methods: {
    ...mapActions("panelInspection", ["volumeDetails", "resetVolumeDetails"]),
  },
  computed: {
    ...mapGetters("panelInspection", [
      "getVolumeDetails",
      "getIsLoadingVolumeDetails",
    ]),
    hasVolumeDetails() {
      return Object.keys(this.getVolumeDetails).length > 0;
    },
  },
};
</script>

<style scoped></style>
