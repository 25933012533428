<template>
  <div class="text--text">
    <v-col
      cols="12"
      class="mb-2 pt-0 h5"
    >
      <span class="font-weight-bold"
        >Detalhes do Volume <span :inner-html.prop="volumeDetails.volumeCodigo | formatVolume"></span></span
      >
    </v-col>
    <v-row
      class="mx-auto base"
      :style="`border: 1px solid ${borderColor} !important`"
    >
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Pedido</span>
        <span class="primary--text font-weight-bold">{{
          volumeDetails.numeroPedido
        }}</span>
      </v-col>
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Cliente</span>
        <span>{{ volumeDetails.clienteNome }}</span>
      </v-col>
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Transportadora</span>
        <span>{{ volumeDetails.transportadora }}</span>
      </v-col>
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Descrição do Kit</span>
        <span>{{ volumeDetails.descricaoKit }}</span>
      </v-col>
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Data de Produção</span>
        <span>{{ volumeDetails.dataProducao | dateDMY }}</span>
      </v-col>
      <v-col
        align="center"
        cols="12"
        sm="6"
        md="4"
      >
        <span class="d-block font-weight-bold">Total de Painéis</span>
        <span
          >{{
            volumeDetails.qntPaineisConferido +
            " / " +
            volumeDetails.qntPaineisPedido
          }}
          <v-icon
            v-if="allPanelsInspected"
            color="green"
            >mdi-check</v-icon
          ></span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VolumeDetails",
  props: {
    volumeDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    allPanelsInspected() {
      const totalPanels = this.volumeDetails.qntPaineisPedido;
      const inspectedPanels = this.volumeDetails.qntPaineisConferido;
      return inspectedPanels === totalPanels && totalPanels > 0;
    },
    borderColor() {
      const isDarkModeOn = this.$vuetify.theme.dark;
      return isDarkModeOn ? "#383838" : "#dee2e6";
    },
  },
};
</script>

<style scoped></style>
