<template>
  <default-modal
    v-model="dialogModel"
    width="550"
  >
    <v-card-title class="text-uppercase font-weight-bold text-center"
      >Conferência finalizada com sucesso</v-card-title
    >
    <v-card-text
      >Todos os painéis do pedido
      <span class="font-weight-bold">{{ orderNumber }}</span> foram
      conferidos</v-card-text
    >
    <v-card-actions>
      <default-button @click="handleClick">Entendi</default-button>
    </v-card-actions>
  </default-modal>
</template>

<script>
import { mapActions } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
export default {
  name: "InspectionCompleted",
  components: {
    DefaultButton,
    DefaultModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderNumber: {},
  },
  methods: {
    ...mapActions("panelInspection", ["reRenderView"]),
    handleClick() {
      this.dialogModel = false;
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.reRenderView();
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
